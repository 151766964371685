import React from 'react';
import styled from 'styled-components';

const Subscribe = styled.div`
    background-color:${props => props.theme.colors[props.bgColor]};
    padding-top:${props => props.theme.spacing.doubleInset};
    padding-bottom:${props => props.theme.spacing.doubleInset};
    display:flex;
    align-items:center;
    flex-direction:column;
`;

const Title = styled.h2`
    color:${props => props.theme.colors[props.fontColor]};
    font-size:18px;
    line-height:24px;
    letter-spacing:1.8px;
    font-weight:${props => props.theme.fontWeight.regular};
    margin-bottom:${props => props.theme.spacing.doubleInset};
    text-align:center;
`;

const SubscribeForm = styled.form`
    position:relative;
`;

const Input = styled.input`
    width:585px;
    height:45px;
    padding:5px 10px;
    font-size:16px;
    line-height:32px;
    border-radius:0px;
    border:0;

    @media (max-width:786px) {
        width:calc( 100vw - 30px );
        font-size:12px;
        -webkit-appearance: none; box-shadow: none !important;
        outline: none;
    }
`;

const Button = styled.button`
    border:0;
    position:absolute;
    top:0;
    right:0;
    font-family:${props => props.theme.fonts.secondFont};
    background-color:${props => props.theme.colors.tertiary};
    color:${props => props.theme.colors.defaultSecondary};
    font-size:16px;
    line-height:32px;
    font-weight:${props => props.theme.fontWeight.bold};
    letter-spacing:0.96px;
    height:45px;
    padding:0 40px;
    transition:all 0.3s linear;
    cursor: pointer;

    &:hover {
        background-color:${props => props.theme.colors.defaultTertiary};
        color:${props => props.theme.colors.primary};
    }

    @media (max-width:786px) {
        padding:0 15px;
        font-size:14px;
    }
    @media (max-width:480px) {
        right:-2px;
    }
`;

const SubscribeArea = ({ subscribe=[] }) => {
    return (
        <Subscribe bgColor="secondary">
            <Title fontColor="defaultSecondary">{subscribe.title}</Title>
            <SubscribeForm className="js-cm-form" id="subForm" action="https://www.createsend.com/t/subscribeerror?description=" method="post" data-id="92D4C54F0FEC16E5ADC2B1904DE9ED1A7F956EE77DBA1EBB8B1A742E1C1FB97F03442B105E296E25B8342BEFCFC4DBE5E3EE83030FBECF7B4994CE832545F732">
                <Input autocomplete="Email" aria-label="Email" className="js-cm-email-input qa-input-email" id="fieldEmail" maxlength="200" name="cm-yuhiidr-yuhiidr" required="" type="email" />
                <Button type="submit">{subscribe.buttonTitle}</Button>
            </SubscribeForm>
        </Subscribe>
    );
}
export default SubscribeArea;
