import React from 'react';
import styled from 'styled-components';
import {
    Link
} from 'react-router-dom';
import LogoSVG from '../../svg/italo-header.svg';
import Navigation from '../navigation';

const Header = styled.header`
    display:flex;
    flex-direction: row;
    align-items:center;
    background-color:${props => props.theme.colors[props.bgColor]};
    padding:10px;
    box-shadow:${props => props.theme.boxShadow.greyShadow};
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index:20;
    height:55px;
`;

const HyperLink = styled(Link)`
    padding:0;
    margin:0;
`;

const Logo = styled.img`
    width:35px;
    margin:0;
`;

const HeaderArea = ({ header = [], cartItems, removeItemFromCart, addOrUpdateCartItem }) => {
    return (
        <Header bgColor="defaultSecondary">
            <HyperLink to="/">
                <Logo src={LogoSVG} alt="Italo Arubano" />
            </HyperLink>
            <Navigation
                menuItems={header.menuItems}
                cartItems={cartItems}
                removeItemFromCart={removeItemFromCart}
                addOrUpdateCartItem={addOrUpdateCartItem}
            />
        </Header>
    );
}
export default HeaderArea;