import React from 'react';
import styled from 'styled-components';
import Hero from '../../components/hero/';
import Title from '../../components/title/';
import Paragraph from '../../components/paragraph/';
import CheckoutForm from '../../components/form/index';

const Main = styled.main`
    margin-top:55px;
    background-color:${props => props.theme.colors[props.bgColor]};

    @media (min-width: 1281px) {
        margin:0 auto;
        width:1280px;
        padding:0;
    }
`;

const CheckoutArea = ({ dynamic_content = [], cartItems, addOrUpdateCartItem, removeItemFromCart }) => {
    return (
        <Main bgColor="defaultQuarterly">
            {dynamic_content.map(({ acf_fc_layout, ...contentBlock }, j) => {
                if (acf_fc_layout === "heroContent") return <Hero key={j} {...contentBlock} />
                if (acf_fc_layout === "titleContent") return <Title key={j} {...contentBlock} />
                if (acf_fc_layout === "paragraphContent") return <Paragraph key={j} {...contentBlock} />
                return null;
            })}
            <CheckoutForm cartItems={cartItems} addOrUpdateCartItem={addOrUpdateCartItem} removeItemFromCart={removeItemFromCart} />
        </Main>
    );
}
export default CheckoutArea;
