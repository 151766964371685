import React from 'react';
import styled from 'styled-components';

const Footer = styled.footer`
    background-color:${props => props.theme.colors[props.bgColor]};
    padding-top:${props => props.theme.spacing.inset};
    padding-bottom:${props => props.theme.spacing.inset};
    display:flex;
    justify-content:space-between;
    border-top:1px solid rgba(0,0,0,0.1);
`;

const MetaInfo = styled.p`
    color:${props => props.theme.colors[props.fontColor]};
    font-weight:${props => props.theme.fontWeight[props.regular]};
    font-size:12px;
    line-height:32px;
    letter-spacing:1.8px;
    margin-bottom:0px;
    padding-left:15px;
    padding-right:15px;
`;

const HyperLink = styled.a`
    color:${props => props.theme.colors[props.fontColor]}; 
    margin-left:5px;
    text-decoration:none;
    &:hover {
        text-decoration:underline;
    }
`;

const FooterArea = () => {
    return (
        <Footer bgColor="defaultTertiary">
            <MetaInfo fontColor="defaultPrimary">
                <HyperLink fontColor="defaultPrimary" target="_blank" rel="noopener" href="https://italoarubano.com/sitemap.xml">
                    SITEMAP
                </HyperLink>
            </MetaInfo>
            <MetaInfo fontColor="defaultPrimary">
                Engineered by 
                <HyperLink fontColor="defaultPrimary" target="_blank" rel="noopener" href="https://www.cr38te.com">
                    CR38TE
                </HyperLink>
            </MetaInfo>
        </Footer>
    );
}
export default FooterArea;
