import React from 'react';
import styled from 'styled-components';
import NoImage from '../../media/no-image.png';

const Image = styled.div`
    background-image:url('${props => [props.bgImage]}');
    background-position:center center;
    background-size:cover;
    background-repeat:no-repeat;
    height:calc( 72px * 2 );
    border-radius:5px;

    @media(max-width:480px) {
        height:70px;
    }
`;

const ImageContent = ({ image }) => <Image bgImage={image !== false ? image['wpmudev-cdn-url'] : NoImage} />
export default ImageContent;