import React, { useState } from 'react';
import styled from 'styled-components';
import MinusIcon from '../../icons/minus';
import AddIcon from '../../icons/add';
import CartIcon from '../../icons/cart';
import { device } from '../../utils/device';

const Header = styled.div`
    display: flex;
    align-items: start;
    background-color: ${(props) => props.theme.colors[props.bgColor]};
    padding:  ${(props) => props.theme.spacing.doubleInset} ${(props) => props.theme.spacing.inset};
    position: sticky;
    top: 55px;
    z-index: 19;

    flex-direction: row;
	justify-content: space-between;

    @media ${device.laptop} {
        align-items: space-between;
        justify-content: space-between;
        flex-direction: row;
		padding:  ${(props) => props.theme.spacing.doubleInset} ${(props) => props.theme.spacing.megaInset};

    }
`;

const ProductName = styled.h1`
    color: ${(props) => props.theme.colors[props.fontColor]};
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: ${(props) => props.theme.spacing.inset};
    margin-top: 0;
    width: 100%;

    @media ${device.laptop} {
        font-size: 25px;
		margin-bottom: 0px;
    }
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;


    @media ${device.laptop} {
        flex-direction: row;
    }
`;

const QuantityContainer = styled.div`
    flex: 50%;
    display: flex;
    flex-direction: row;

    background-color: ${(props) => props.theme.colors.defaultSecondary};
    padding: ${(props) => props.theme.spacing.inset} 10px;
    border: 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 0;

    @media ${device.laptop} {
        padding: ${(props) => props.theme.spacing.inset};

        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`;

const QtyButton = styled.div`
    cursor: pointer;
    display: block;
`;

const RemoveQuantity = styled(MinusIcon)`
    cursor: pointer;
    display: block;
`;

const AddQuantity = styled(AddIcon)`
    cursor: pointer;
    display: block;
`;

const Input = styled.input`
    width: 25px;

    border: 0;
    text-align: center;
    font-weight: 700;
    color: ${(props) => props.theme.colors.defaultPrimary};
    font-size: 14px;

    @media ${device.laptop} {
        width: 100px;
    }
`;

const ButtonName = styled.p`
    margin: 0px;
    font-size: 0px;
    @media ${device.laptop} {
        font-size: 16px;
        padding-left: 10px;
    }
`;

const Button = styled.div`
	height:50px;
    flex: 50%;
    border: 0;
    color: ${(props) => props.theme.colors[props.fontcolor]};
    background-color: ${(props) => props.theme.colors[props.bgColor]};
    font-weight: ${(props) => props.theme.fontWeight.mediumBold};
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: 3px;
    padding: ${(props) => props.theme.spacing.inset};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    text-transform: capitalize;
    margin-bottom: 0;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s linear;
	pointer-events: ${(p)=>[p.disabled]};

    &:hover {
        background-color: ${(props) => props.theme.colors.primary};
    }
`;
const Price = styled.p`
    color: ${(props) => props.theme.colors.defaultPrimary};
    font-size: 15px;
    line-height: 20px;
    padding-right: 8px;
    margin-bottom: ${(props) => props.theme.spacing.inset};

    width: 100%;
    span {
        font-weight: 400;
        color: ${(props) => props.theme.colors[props.fontColor]};
    }

    @media ${device.tablet} {
        font-size: 20px;
        line-height: 38px;
		margin-bottom: 0;

    }
`;

const Paragraph = styled.p`
    color: ${(props) => props.theme.colors.defaultSecondary};
    font-size: 10px;
    line-height: 16px;
    padding-right: 8px;
    margin-bottom: 0;
    font-style: italic;

    width: 100%;
    span {
        font-weight: 400;
        color: ${(props) => props.theme.colors[props.fontColor]};
    }

    @media ${device.tablet} {
	font-size: 12px;

    }
`;

const ProductInfo = styled.div`
    @media ${device.tablet} {
        margin-left: 15px;
    }
`;

const ProductHeader = ({ addOrUpdateCartItem, ...props }) => {
    const [count, setCount] = useState(0);
    const { name, ID, price, unitDescription, paragraph } = props;

    return (
        <Header bgColor='defaultPrimary'>
            <ProductInfo>
                <ProductName fontColor='defaultSecondary'>{name}</ProductName>
                <Price fontColor='tertiary'>
                    <span>{`Awg. ${price}/${unitDescription}`}</span>
                </Price>
				<Paragraph>
					{paragraph}
				</Paragraph>
            </ProductInfo>
            <Actions>
                <QuantityContainer>
                    <QtyButton
                        onClick={() => setCount(count > 0 ? count - 1 : 0)}
                    >
                        <RemoveQuantity
                            className='actionIcon'
                            iconColor='defaultTertiary'
                        />
                    </QtyButton>
                    <Input
                        type='number'
                        value={count}
                        min='0'
                        name='quantity'
                        step='1'
                        onChange={(e) => setCount(parseInt(e.target.value))}
                    />
                    <QtyButton onClick={() => setCount(count + 1)}>
                        <AddQuantity
                            className='actionIcon'
                            iconColor='defaultTertiary'
                        />
                    </QtyButton>
                </QuantityContainer>
                <Button
                    bgColor={count<=0 ?"defaultTertiary":'tertiary'}
                    fontColor='defaultSecondary'
                    onClick={() => addOrUpdateCartItem(ID, count, price)}
					disabled={count<=0 ?"none":"auto"}
					
                >
                    <CartIcon iconColor='defaultPrimary' />
                    <ButtonName>Add to Cart</ButtonName>
                </Button>
            </Actions>
        </Header>
    );
};
export default ProductHeader;
