import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    padding-left:${props => props.theme.spacing.inset};
    padding-right:${props => props.theme.spacing.inset};

    @media (min-width: 1281px) {
        width:1280px;
        margin-left:auto;
        margin-right:auto;
        padding:0;
    }
`;

const Paragraph = styled.p`
    flex:100%;
    font-size:12px;
    line-height:18px;
    margin-bottom:${props => props.theme.spacing.inset};
`;

const ParagraphArea = ({ paragraph }) => {
    return (
        <Container>
            <Paragraph dangerouslySetInnerHTML={{ __html: paragraph }} />
        </Container>
    );
}
export default ParagraphArea;
