import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    Link,
} from 'react-router-dom';
import Image from '../../components/image/image';
import { getAttachments } from '../../utils/data';

const CardContainer = styled.div`
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    padding-left:${props => props.theme.spacing.inset};
    padding-right:${props => props.theme.spacing.inset};

    @media (min-width: 1281px) {
        width:1280px;
        margin-left:auto;
        margin-right:auto;
        padding:0;
    }
`;

const Card = styled(Link)`
    margin-bottom:${props => props.theme.spacing.inset};
    background-color:${props => props.theme.colors[props.bgcolor]};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius:5px;
    justify-content: center;
    flex-wrap:wrap;
    text-align:center;
    cursor:pointer;
    text-decoration:none;

    &:nth-child(2n+0) {
        margin-left:${props => props.theme.spacing.inset};
    }

    &:hover {
        background-color:${props => props.theme.colors.secondary};
    }

    @media (min-width: 1281px) {
        margin-right:15px;
        width:calc( calc( 1280px - 45px ) / 4 );
        &:nth-child(2n+0) {
            margin-left:0;
        }
        &:nth-child(4n+0) {
            margin-right:0;
        }
    }

    @media(max-width:1280px) {
        margin-right:15px;
        width:calc( calc( 100vw - 60px ) / 3 );
        &:nth-child(2n+0) {
            margin-left:0;
        }
        &:nth-child(3n+0) {
            margin-right:0;
        }  
    }

    @media(max-width:700px) {
        margin-right:15px;
        width:calc( calc( 100vw - 45px ) / 2 );
        margin:0 15px 15px 0;  
        &:nth-child(3n+0) {
            margin-right:15px;
        }  
        &:nth-child(2n+0) {
            margin-right:0;
        }      
    }
`;

const CardTitle = styled.h2`
    margin-bottom:0;
    padding:8px 15px;
    font-weight:${props => props.theme.fontWeight.medium};
    font-size:12px;
    line-height:18px;
    color:${props => props.theme.colors[props.fontColor]};
`;

const CategoriesArea = ({ categories = [] }) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const imgIDs = categories.map(({ image }) => image).filter(image => image !== false && image !== undefined);
        async function fetchData() {
            try {
                const images = await getAttachments(imgIDs, 'medium');
                images.forEach(image => {
                    for (let i = 0; i < categories.length; i++) {
                        if (categories[i].image === parseInt(image.ID)) {
                            categories[i].image = image;
                        }
                    }
                });
            }
            catch (err) {
                console.log(err);
            }
            setLoading(false);
        }
        fetchData();
    }, [categories]);
    if (loading) return null;
    return (
        <CardContainer>
            {categories.map(({ acf_fc_layout, ...categoryInfo }, j) =>
                <Card to={`products/${categoryInfo.type}/`}
                    key={`category-${j}`}
                    bgcolor="defaultSecondary"
                >
                    <Image image={categoryInfo.image} />
                    <CardTitle fontColor="defaultPrimary">{categoryInfo.title}</CardTitle>
                </Card>
            )}
        </CardContainer>
    );
}
export default CategoriesArea;
