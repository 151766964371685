import styled from 'styled-components';
import { device } from '../../utils/device';

const StyledImg = styled.img`
    width: 100%;
	max-width: 1200px;
    margin-bottom: 15px;
    max-width: 100%;
    overflow: hidden;
	max-height: 250px;

    @media ${device.tablet} {
        max-height: ${(p) => `${p.tooSmall ? 'unset' : '350px'}`};
        min-height: ${(p) => `${p.tooSmall ? 'unset' : '350px'}`};

        margin-bottom: 30px;
    }

	@media ${device.laptopL} {
        max-height: ${(p) => `${p.tooSmall ? 'unset' : '500px'}`};
        min-height: ${(p) => `${p.tooSmall ? 'unset' : '500px'}`};

        margin-bottom: 30px;
    }
`;

export default function Image({ image = {}, tooSmall }) {
    return (
        image ?
            <StyledImg
                src={image.url}
                alt={`${image.alt} - Italo Aruba`}
                tooSmall={tooSmall}
            />
            :
            null
    );
}
