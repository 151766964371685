import React from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";

export const categoriesTypeOptions = [
    { value: 'all', text: 'All' },
    { value: 'floorTiles', text: 'Floor Tiles' },
    { value: 'wallTiles', text: 'Wall Tiles' },
    { value: 'thinset', text: 'Thinset' },
    { value: 'grout', text: 'Grout' },
    { value: 'marbleStone', text: 'Marble & Stone' },
    { value: 'toilets', text: 'Toilets' },
    { value: 'bathroomFixtures', text: 'Bathroom Fixtures' },
    { value: 'roofing', text: 'Roofing' },
    { value: 'toolsPerfiles', text: 'Tools & Perfiles' },
    { value: 'mosaicDecors', text: 'Mosaic & Decors' },
];

const Container = styled.div`
    display:flex;
    margin-left:${props => props.theme.spacing.inset};
    margin-right:${props => props.theme.spacing.inset};

    @media (min-width: 1281px) {
        width:1280px;
        margin-left:auto;
        margin-right:auto;
        padding:0;
    }
`;

const FormItem = styled.div`
    width:100%;
    margin-bottom:${props => props.theme.spacing.inset};
`;

const Select = styled.select`
    width:100%;
    height:30px;
    font-size:12px;
`;

const Option = styled.option`
    font-size:12px;
`;

const Label = styled.label`
    color:${props => props.theme.colors.defaultPrimary};
    font-size:12px;
    line-height:24px;
    font-weight:${props => props.theme.fontWeight.mediumBold};
`;

const FilterProductsArea = ({ category }) => {
    let history = useHistory();

    const handleClick = event => {
        const path = event.target.value !== 'all' ? event.target.value : ''
        history.push(`/products/${path}`);
        event.preventDefault();
    }

    return (
        <Container>
            <FormItem>
                <Label htmlFor="categoriesTypeOptions">Filter per category *</Label>
                <Select id="categoriesTypeOptions" value={category} onChange={handleClick}>
                    {categoriesTypeOptions.map(({ value, text }, i) =>
                        <Option key={`${value}-${i}`} value={value}>{text}</Option>
                    )}
                </Select>
            </FormItem>
        </Container>
    );
}
export default FilterProductsArea;
