import React, { Component } from 'react';
import { Route } from 'react-router';
import { getPage } from '../../utils/data';
import Loading from '../loading';
import PageError from '../page-error';
import HomePage from '../../screens/homePage/';
import ProductsPage from '../../screens/overviewProducts';
import ProductItem from '../../screens/singleProduct';
import CheckoutPage from '../../screens/checkoutPage';
import ThankYouPage from '../../screens/thankYouPage';

const components = {
    'index': HomePage,
    'products-overview.php': ProductsPage,
    'checkout.php': CheckoutPage,
    'thank-you.php': ThankYouPage,
}

class Page extends Component {
    state = {
        loading: true
    };

    async componentDidMount() {
        const title = this.props.post_name || 'page';
        this.setState({ loading: true });
        try {
            let content = await getPage(title);
            this.setState({ content, error: undefined });
        }
        catch (error) {
            this.setState({ error });
        }
        this.setState({ loading: false });
    }

    render() {
        const {
            loading,
            content = {},
            error
        } = this.state;
        if (loading) {
            return <Loading />;
        }
        else if (error) {
            return <PageError error={error} />;
        }
        else {
            let { template } = content;
            let Template = components[template];
            if (!Template) Template = components['index'];
            return (
                <Template {...content} {...this.props} />
            )
        }
    }
}
export default Page;

export const getPagePath = (page, asLink = false) => {
    let path = `/${page.post_name || 'unkown'}`;
    if (path === '/home') {
        //Add the root to any path called 'home'
        path = asLink ? '/' : '/(|home)';
    } else {
        return `${path}**`;
    }
    return path;
}

export const getPageRoutes = (website, cartItems) => {
    let { pages = [], addOrUpdateCartItem, removeItemFromCart } = website;
    let pageRoutes = pages.map((page, index) => {
        const path = getPagePath(page);
        return (
            <Route key={index} path={path} exact render={(routerProps) =>
                <Page {...routerProps} {...page} cartItems={cartItems} addOrUpdateCartItem={addOrUpdateCartItem} removeItemFromCart={removeItemFromCart} />
            } />
        );
    });
    // pageRoutes.push(
    //     <Route key='products-overview-type' path="/products/:type" render={routerProps =>
    //         <ProductsPage {...routerProps} cartItems={cartItems} addOrUpdateCartItem={addOrUpdateCartItem} removeItemFromCart={removeItemFromCart} />
    //     } />
    // )
    // pageRoutes.push(
    //     <Route key='products-overview' path="/products" exact render={routerProps =>
    //         <ProductsPage {...routerProps} cartItems={cartItems} addOrUpdateCartItem={addOrUpdateCartItem} removeItemFromCart={removeItemFromCart} />
    //     } />
    // )
    pageRoutes.push(
        <Route key='single-product' path="/product/:slug" render={routerProps =>
            <ProductItem {...routerProps} addOrUpdateCartItem={addOrUpdateCartItem} />
        } />
    )
    return pageRoutes;
}