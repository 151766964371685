import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SingleProduct from '../../components/cards/singleCard';
import { getAttachments } from '../../utils/data';
import Loading from '../loading';

const Container = styled.div`
    background-color:${props => props.theme.colors[props.bgColor]};
    padding:${props => props.theme.spacing.inset};

    @media (min-width: 1281px) {
        width:1280px;
        margin-left:auto;
        margin-right:auto;
        padding-left:0;
        padding-right:0;
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
    }
    @media (max-width: 1280px) {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
    }
    @media (max-width: 1000px) {
        display:flex;
        flex-direction:column;
        flex-wrap:no-wrap;
    }
`;

const ProductsArea = ({ posts = [], loading = true }) => {
    const [newPosts, setnewPosts] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const size = 200;
            try {
                const imageProductIDs = posts.map(({ imageProduct }) => imageProduct).filter(image => image !== false && image !== undefined);
                const nrOfProductIDsChunks = Math.ceil(imageProductIDs.length / size);
                for (let i = 0; i < nrOfProductIDsChunks; i++) {
                    const chunkIDs = imageProductIDs.splice(0, size)
                    const productImages = await getAttachments(chunkIDs, 'medium');
                    if (productImages && productImages.length > 0) {
                        productImages.forEach(image => {
                            for (let i = 0; i < posts.length; i++) {
                                if (posts[i].imageProduct === parseInt(image.ID)) {
                                    posts[i].imageProduct = image;
                                }
                            }
                        });
                    }
                }

                const ambientImagesIDs = posts.map(({ imageAmbient }) => imageAmbient).filter(image => image !== false && image !== undefined);

                const nrOfambientImagesIDsChunks = Math.ceil(ambientImagesIDs.length / size);
                for (let i = 0; i < nrOfambientImagesIDsChunks; i++) {
                    const chunkIDs = ambientImagesIDs.splice(0, size)
                    const ambientImages = await getAttachments(chunkIDs, 'medium');
                    if (ambientImages && ambientImages.length > 0) {
                        ambientImages.forEach(image => {
                            for (let i = 0; i < posts.length; i++) {
                                if (posts[i].imageAmbient === parseInt(image.ID)) {
                                    posts[i].imageAmbient = image;
                                }
                            }
                        });
                    }
                }
                setnewPosts(posts);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchData();
    }, [posts]);
    if(loading) return <Loading fullHeight={false} />
    return (
        <Container bgColor="defaultQuarterly">
            {newPosts?.length > 0 && newPosts.map((productItem, i) => <SingleProduct key={`productItem` + i} {...productItem} />)}
        </Container>
    );
}
export default ProductsArea;
