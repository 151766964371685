import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CartIcon from '../../icons/cart';
import { Link } from 'react-router-dom';
import CartProductArea from './cart-product-area';

const Container = styled.div``;

const CartContainer = styled.div`
    margin-right: 15px;
    cursor: pointer;
`;

const CartItemsContainer = styled.div`
    height: 200px;
    overflow-y: scroll;
`;

const Notification = styled.span`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    font-weight: 700;
    font-size: 10px;
    top: 6px;
    right: 13px;
    background-color: ${(props) => props.theme.colors[props.bgColor]};
    color: ${(props) => props.theme.colors[props.fontColor]};
    align-items: center;
    display: flex;
    justify-content: center;
`;

const CartDropdown = styled.div`
    position: absolute;
    top: 55px;
    right: 15px;
    background-color: ${(props) => props.theme.colors[props.bgColor]};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    width: calc(100vw - 30px);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0 0 3px 3px;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    transition: opacity 300ms;

    @media (min-width: 1281px) {
        width: calc(100vw / 3);
    }
    @media (max-width: 1280px) {
        width: calc(100vw / 2);
    }
    @media (max-width: 800px) {
        width: calc(100vw - 30px);
    }
`;

const Header = styled.h2`
    padding: ${(props) => props.theme.spacing.inset};
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const Button = styled(Link)`
    flex: 100%;
    border: 0;
    color: ${(props) => props.theme.colors[props.fontcolor]};
    background-color: ${(props) => props.theme.colors[props.bgcolor]};
    font-weight: ${(props) => props.theme.fontWeight.mediumBold};
    display: block;
    text-align: center;
    border-radius: 0 0 3px 3px;
    padding: ${(props) => props.theme.spacing.inset};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    text-transform: capitalize;
    margin-bottom: 0;
    cursor: pointer;
    text-decoration: none;
`;

const Paragraph = styled.p`
    padding: ${(props) => props.theme.spacing.inset};
    color: ${(props) => props.theme.colors.defaultPrimary};
    font-size: 12px;
    line-height: 18px;
    font-weight: ${(props) => props.theme.fontWeight.mediumBold};
`;

const CartArea = ({ items = [], removeItemFromCart, addOrUpdateCartItem }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onWindowClick = function (e) {
        if (document.getElementById('cart-container').contains(e.target)) {
            const shouldOpen = !isOpen;
            setIsOpen(shouldOpen);
        } else {
            setIsOpen(false);
        }
    };

    const hideCart = function (e) {
        setIsOpen(false);
    };

    useEffect(() => {
        window.addEventListener('click', onWindowClick);
        return () => {
            window.removeEventListener('click', onWindowClick);
        };
    });

    return (
        <Container>
            <CartContainer id='cart-container'>
                <CartIcon iconColor='defaultPrimary' />
                <Notification bgColor='warning' fontColor='defaultSecondary'>
                    {items.length || 0}
                </Notification>
            </CartContainer>
            <CartDropdown
                id='cart-dropdown'
                bgColor='defaultSecondary'
                isOpen={isOpen}
            >
                <Header>Your Shopping cart</Header>
                <CartItemsContainer id='cartItemsHolder'>
                    {(items || {}).length > 0 &&
                        items.map((item, i) => (
                            <CartProductArea
                                key={`cart-item-${item.ID}-${i}`}
                                removeItemFromCart={removeItemFromCart}
                                addOrUpdateCartItem={addOrUpdateCartItem}
                                {...item}
                            />
                        ))}
                    {items.length < 1 && (
                        <Paragraph>
                            There are no products available in your cart.
                        </Paragraph>
                    )}
                </CartItemsContainer>
                {items.length > 0 && (
                    <Button
                        bgcolor='tertiary'
                        fontcolor='defaultPrimary'
                        to='/checkout'
                        onClick={hideCart}
                    >
                        Go to checkhout
                    </Button>
                )}
            </CartDropdown>
        </Container>
    );
};
export default CartArea;
