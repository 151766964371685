import styled from 'styled-components';
import { device } from '../../utils/device';

const ImgContainer = styled.div`
    margin-bottom: 0px;
    @media ${device.tablet} {
        margin-bottom: 30px;
    }
`;

const StyledImg = styled.img`
    width: calc(100% / 3);
    padding: ${(p) =>
        `0px ${p.padding === 'left' ? '7.5px' : '0px'} 0px ${
            p.padding === 'right' ? '7.5px' : '0px'
        }`};
    min-height: 150px;
    max-height: 150px;
    @media ${device.tablet} {
        max-height: 300px;
        min-height: 300px;
        padding: ${(p) =>
            `0px ${p.padding === 'left' ? '15px' : '0px'} 0px ${
                p.padding === 'right' ? '15px' : '0px'
            }`};
    }
`;
const StyledImgCenter = styled.img`
    width: calc(100% / 3);
    padding: 0 7.5px 0 7.5px;
    min-height: 150px;
    max-height: 150px;
    @media ${device.tablet} {
        max-height: 300px;
        min-height: 300px;
        padding: 0 15px 0 15px;
    }
`;

export default function ThreeImages({ leftImage, centerImage, rightImage }) {
    return (
        <ImgContainer>
            <StyledImg
                padding={'left'}
                src={leftImage.url}
                alt={`${leftImage.alt} - Italo Aruba`}
            />
            <StyledImgCenter
                src={centerImage.url}
                alt={`${centerImage.alt} - Italo Aruba`}
            />
            <StyledImg
                padding={'right'}
                src={rightImage.url}
                alt={`${rightImage.alt} - Italo Aruba`}
            />
        </ImgContainer>
    );
}
