import styled from 'styled-components';
import { device } from '../../utils/device';

const ImgContainer = styled.div`
    margin-bottom: 15px;
    @media ${device.tablet} {
        margin-bottom: 30px;
    }
`;

const StyledImg = styled.img`
    width: 50%;
    padding: ${(p) =>
        `0px ${p.padding === 'left' ? '7.5px' : '0px'} 0px ${p.padding === 'right' ? '7.5px' : '0px'
        }`};

    @media ${device.tablet} {
	
        padding: ${(p) =>
        `0px ${p.padding === 'left' ? '15px' : '0px'} 0px ${p.padding === 'right' ? '15px' : '0px'
        }`};
       max-height: ${(p) => `${p.tooSmall ? '150px' : '200px'}`};
        min-height: ${(p) => `${p.tooSmall ? '150px' : '200px'}`};
    }

	@media ${device.laptopL} {
	
	padding: ${(p) =>
        `0px ${p.padding === 'left' ? '15px' : '0px'} 0px ${p.padding === 'right' ? '15px' : '0px'
        }`};
   max-height: ${(p) => `${p.tooSmall ? '150px' : '300px'}`};
	min-height: ${(p) => `${p.tooSmall ? '150px' : '300px'}`};
}
`;

export default function TwoImages({ leftImage, rightImage, tooSmall }) {
    return (
        <ImgContainer>
            {
                leftImage &&
                <StyledImg
                    padding={'left'}
                    src={leftImage.url}
                    alt={`${leftImage.alt} - Italo Aruba`}
                    tooSmall={tooSmall}
                />
            }
            {rightImage &&
                <StyledImg
                    padding={'right'}
                    src={rightImage.url}
                    alt={`${rightImage.alt} - Italo Aruba`}
                    tooSmall={tooSmall}

                />
            }
        </ImgContainer>
    );
}
