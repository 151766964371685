import styled from 'styled-components'
import { device } from '../../utils/device'

const ImgContainer = styled.div`
    margin-bottom: 15px;
    display: flex;
    flex-direction: ${(p) => [p.rowReverse]};

    @media ${device.tablet} {
        margin-bottom: 30px;
    }
`

const StyledImg = styled.img`
    width: calc(100% / 3);
    padding: ${(p) =>
        `0px ${p.padding === 'left' ? '7.5px' : '0px'} 0px ${
            p.padding === 'right' ? '7.5px' : '0px'
        }`};
    max-height: 150px;
    min-height: 150px;
    @media ${device.tablet} {
        padding: ${(p) =>
            `0px ${p.padding === 'left' ? '15px' : '0px'} 0px ${
                p.padding === 'right' ? '15px' : '0px'
            }`};
        max-height: 250px;
        min-height: 250px;
    }

	@media ${device.laptopL} {
      
		max-height: 300px;
        min-height: 300px;
    }
`
const StyledImgStretch = styled.img`
    width: calc((100% / 3) * 2);
    padding: ${(p) =>
        `0px ${p.padding === 'left' ? '7.5px' : '0px'} 0px ${
            p.padding === 'right' ? '7.5px' : '0px'
        }`};
    max-height: 150px;
    min-height: 150px;
    @media ${device.tablet} {
        padding: ${(p) =>
            `0px ${p.padding === 'left' ? '15px' : '0px'} 0px ${
                p.padding === 'right' ? '15px' : '0px'
            }`};
        max-height: 250px;
        min-height: 250px;
    }
	@media ${device.laptopL} {
      
	  max-height: 300px;
	  min-height: 300px;
  }
`

export default function TwoImagesStretch({
    leftImage,
    rightImage,
    rowReverse,
}) {
    return (
        <ImgContainer rowReverse={rowReverse ? 'row-reverse' : ''}>
            <StyledImg
                padding={rowReverse ? 'right' : 'left'}
                src={leftImage.url}
                alt={`${leftImage.alt} - Italo Aruba`}
            />
            <StyledImgStretch
                padding={rowReverse ? 'left' : 'right'}
                src={rightImage.url}
                alt={`${rightImage.alt} - Italo Aruba`}
            />
        </ImgContainer>
    )
}
