import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    min-width: 20px;
    width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    color: grey;
    user-select: none;
`;

const SVG = styled.svg`
    width: 100%;
    height: 100%;
    color:${props => props.theme.colors[props.fontColor]};
    transition:all 0.2s linear;
`;

const ChevronRightIcon = ({ className, iconColor }) => (
    <Container className={className} >
        <SVG fontColor={iconColor} aria-hidden="true" focusable="false" data-prefix="fad" data-icon="chevron-circle-right" className="svg-inline--fa fa-chevron-circle-right fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm113.9 265L234.4 408.5a23.9 23.9 0 0 1-33.9 0l-17-17a23.9 23.9 0 0 1 0-33.9L285.1 256 183.5 154.4a23.9 23.9 0 0 1 0-33.9l17-17a23.9 23.9 0 0 1 33.9 0L369.9 239a24 24 0 0 1 0 34z" opacity="0.4"/><path fill="currentColor" d="M369.9 273L234.4 408.5a23.9 23.9 0 0 1-33.9 0l-17-17a23.9 23.9 0 0 1 0-33.9L285.1 256 183.5 154.4a23.9 23.9 0 0 1 0-33.9l17-17a23.9 23.9 0 0 1 33.9 0L369.9 239a24 24 0 0 1 0 34z"/></g></SVG>
    </Container>
);

export default ChevronRightIcon;