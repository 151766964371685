import React from 'react';
import styled from 'styled-components';
import Preloader from './preloader.gif';

const Container = styled.div`
    height:${props => `calc( ${props.fullHeight ? '100' : '20'}vh - 55px )`};
    margin-top:55px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
`;

const Image = styled.img`
    width: 25px;
    height: 25px;
    display: block;
    margin: 0 auto;
`;

const Loading = ({fullHeight = true}) => {
    return (
        <Container fullHeight={fullHeight}>
            <Image src={Preloader} alt="preloader Italo Arubano" />
        </Container>
    );
}
export default Loading;