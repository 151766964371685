

import React from 'react';
import styled from 'styled-components';
import {
    Link
} from 'react-router-dom';

const Container = styled.div`
    padding-left:${props => props.theme.spacing.inset};
    padding-right:${props => props.theme.spacing.inset};

    @media (min-width: 1281px) {
        width:1280px;
        margin-left:auto;
        margin-right:auto;
        padding:0;
    }
`;

const Button = styled(Link)`
    color:${props => props.theme.colors[props.fontcolor]};
    background-color:${props => props.theme.colors[props.bgcolor]};
    display:block;
    text-align:center;
    border-radius:3px;
    padding:${props => props.theme.spacing.inset};
    text-transform:capitalize;
    font-size:12px;
    line-height:18px;
    font-weight:${props => props.theme.fontWeight.mediumBold};
    margin-bottom:${props => props.theme.spacing.inset};
    cursor:pointer;
    text-decoration:none;
    transition:all 0.2s linear;

    &:hover {
        color:${props => props.theme.colors.tertiary};
        background-color:${props => props.theme.colors.defaultPrimary};
    }
`;

const ButtonArea = ({ button=[] }) => {
    return (
        <Container>
            <Button 
                to={`/products/?cat=all`}
                bgcolor="tertiary"
                fontcolor="defaultSecondary"
            >
                {button.buttonTitle}
            </Button>
        </Container>
    );
}
export default ButtonArea;
