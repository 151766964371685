import React from 'react';
import styled from 'styled-components';

const List = styled.div`
    display:flex;
    margin-bottom:${props => props.theme.spacing.doubleInset};

    &.nawContainer {
        margin-bottom:0;
    }

    @media(max-width:580px) {
        &.nawContainer {
            flex-direction:column;
        }
    }
`;
const NawInfo = styled.span`
    padding-left:${props => props.theme.spacing.inset};
    padding-right:${props => props.theme.spacing.inset};
    font-size:12px;
    line-height:32px;
    letter-spacing:1.8px;
    color:${props => props.theme.colors.secondary};

    @media(max-width:580px) {
        text-align:center;
    }
`;

const NawInformation = ({ nawInformation=[] }) => {
    return (
        <List className="nawContainer">
            {nawInformation.map(({ content = '' }, i) =>
                <NawInfo key={`naw-info-${i}`}>{content}</NawInfo>
            )}
        </List>
    );
}
export default NawInformation;
