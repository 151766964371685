import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Products from '../../components/cards/productsCard';
import Hero from '../../components/hero/';
import Title from '../../components/title/';
import Paragraph from '../../components/paragraph/';
import { getObjectsByType } from '../../utils/data';
import FilterProductsArea from '../../components/filter';
import { categoriesTypeOptions } from '../../components/filter';

const Main = styled.main`
    margin-top:55px;
    background-color:${props => props.theme.colors[props.bgColor]};
`;

const MainArea = ({ dynamic_content = [] }) => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState('');

    useEffect(() => {
        async function fetchData() {
            const path = window.location.pathname.split('/').filter(el => el && el !== 'products' ? el : null);
            const newCategory = path.length > 0 && categoriesTypeOptions.find(obj => obj.value === path[0]) !== undefined ? path[0] : 'all';
            if (newCategory !== category) {
                setCategory(newCategory);
                setLoading(true);
                setPosts([]);
                let options =
                {
                    perPage: -1,
                };

                if (newCategory !== 'all') {
                    options.filter = {
                        by: 'type',
                        value: newCategory
                    }
                }

                try {
                    const res = await getObjectsByType('product', options)
                    if (res.status !== 404) {
                        setPosts(res);
                    }
                }
                catch (err) {
                    console.log(err);
                }
                finally {
                    setLoading(false);
                }
            }
        }
        fetchData();
    });

    return (
        <Main bgColor="defaultQuarterly">
            {dynamic_content.map(({ acf_fc_layout, ...contentBlock }, j) => {
                if (acf_fc_layout === "heroContent") return <Hero key={j} {...contentBlock} />
                if (acf_fc_layout === "titleContent") return <Title key={j} {...contentBlock} />
                if (acf_fc_layout === "paragraphContent") return <Paragraph key={j} {...contentBlock} />
                return null;
            })}
            <FilterProductsArea category={category} />
            <Products posts={posts} loading={loading} />
        </Main>
    );
}
export default MainArea;
