import React from 'react';
import styled from 'styled-components';
import { device } from '../../utils/device';
import BGImage from '../../components/image/bg-image';

const Description = styled.div`
    display: flex;
    justify-content: center;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.defaultTertiary};
    padding-top: ${(props) => props.theme.spacing.doubleInset};
    padding-bottom: ${(props) => props.theme.spacing.doubleInset};
    flex-direction: column;

    @media ${device.tablet} {
        flex-direction: row;
    }
`;

const Slider = styled.div`
    display: flex;
    padding-left: ${(props) => props.theme.spacing.doubleInset};
    padding-right: ${(props) => props.theme.spacing.doubleInset};
    flex-wrap: nowrap;
    scroll-padding-left: 10px;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;

    width: 100%;

    @media ${device.laptop} {
        overflow-x: unset;
        flex-wrap: wrap;
        padding-left: ${(props) => props.theme.spacing.inset};
        padding-right: ${(props) => props.theme.spacing.inset};
        display: flex;
        flex-direction: row;
        padding: 0;

        @media (orientation: landscape) {
            flex-direction: row;
        }
    }
`;

const Price = styled.p`
    color: ${(props) => props.theme.colors.defaultPrimary};
    font-size: 15px;
    line-height: 20px;
    padding-right: 8px;
    margin-bottom: ${(props) => props.theme.spacing.inset};
    padding-left: 15px;

    span {
        font-weight: 400;
        color: ${(props) => props.theme.colors[props.fontColor]};
    }

    @media ${device.tablet} {
        line-height: 38px;

    }

    @media (max-width: 1281px) {
        font-size: 12px;

    }
`;

const Category = styled.span`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    padding-left: ${(p) => p.theme.spacing.inset};

    @media ${device.tablet} {
        margin-bottom: ${(p) => p.theme.spacing.inset};

        padding-left: ${(p) => p.theme.spacing.doubleInset};
    }

    @media (max-width: 1281px) {
        font-size: 12px;
    }
`;

const SubContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;

    @media ${device.tablet} {
        margin-top: 0px;

        width: calc(100% / 3);
    }
`;

const Color = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 0px;

    width: 100%;
    @media ${device.tablet} {
        border-left: 1px;
        border-right: 1px;
        border-style: solid;
        border-color: ${(props) => props.theme.colors.defaultTertiary};
        width: calc(100% / 3);
    }
    @media (max-width: 1281px) {
        @media (orientation: portrait) {
            align-items: center;
        }
    }
`;

const Strong = styled.strong`
    color: ${(props) => props.theme.colors[props.fontColor]};
    font-size: ${(props) => props.theme.fontSizes[props.fontSize]};
    font-weight: 700;
    margin-bottom: ${(props) => props.theme.spacing.inset};
    margin-top: 0;
    padding-left: ${(props) => props.theme.spacing.doubleInset};

    @media (max-width: 1281px) {
        padding: 0;
        display: flex;
        justify-content: center;
        font-size: 20px;
    }
`;

const Paragraph = styled.p`
    font-weight: 400;
    font-size: 12px !important;
    line-height: 18px;
    color: ${(props) => props.theme.colors[props.fontColor]};
    padding-left: 15px;

    @media ${device.tablet} {
        font-size: 14px;
    }
`;

const StyledImage = styled.div`
    height: 150px;
    width: 150px;
    margin-right: 0;
    margin-left: 15px;
	position: relative;

    scroll-snap-align: start;
    transition: all 0.3s linear 0s;

    > div {
        height: 85%;
        width: 125px;
    }

    @media ${device.laptop} {
        height: 100px;
        width: 100px;
        margin-left: 30px;
        padding: 0;

        margin-bottom: 15px;

        > div {
            height: 100%;
            width: unset;
        }
    }

    @media ${device.tablet} {
        height: 100px;
        width: 100px;
        margin-left: 30px;
        padding: 0;

        margin-bottom: 15px;

        > div {
            height: 100%;
            width: 100px;
        }
    }
`;

const ProductDescription = (props) => {
    const {
        price,
        unitDescription,
        description,
        type,
        availableColors = {},
        attributes,
        productColors
    } = props;

    if (!type) {
        return null;
    }

    return (
        <Description>
            <SubContent>
                <Price fontColor="tertiary">
                    Price: <span>{`Awg. ${price}/${unitDescription}`}</span>
                </Price>

                <Paragraph
                    fontColor="defaultPrimary"
                    spacing="doubleInset"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </SubContent>
            <Color>
                <Strong fontColor="primary" fontSize="medium">
                    {availableColors.title}
                </Strong>
                <Slider>
                    {productColors?.length > 0 &&
                        productColors.map(({ imageAmbient, imageProduct, attributes }, i) => {
                            const color = attributes && attributes?.find(item => item.attribute === "Color");
                            return (
                                <StyledImage key={`product-description-image-${i}`}>
                                    <BGImage
                                        imgID={
                                            imageProduct === false
                                                ? imageAmbient
                                                : imageProduct
                                        }
                                        size={'full'}
                                        color={color && color.value}
                                    />
                                </StyledImage>
                            );
                        })}
                    {/* <Paragraph fontColor="warning" className="no-images">There are no images available for this product.</Paragraph> */}
                </Slider>
            </Color>
            <SubContent>
                {/* <Category>
                    <strong>Size:</strong> {size}
                </Category> */}
                {attributes?.length > 0 &&
                    attributes.map(({ attribute, value }, i) => (
                        <Category key={`attribute-${i}`}>
                            <strong>{attribute}:</strong> {value}
                        </Category>
                    ))}
            </SubContent>
        </Description>
    );
};
export default ProductDescription;
