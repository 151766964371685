import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../../icons/facebook';
import InstagramIcon from '../../icons/instagram';

const List = styled.div`
    display:flex;
    margin-bottom:${props => props.theme.spacing.doubleInset};

    &.nawContainer {
        margin-bottom:0;
    }

    @media(max-width:580px) {
        &.nawContainer {
            flex-direction:column;
        }
    }
`;

const ListLink = styled.a`
    padding-left:${props => props.theme.spacing.inset};
    padding-right:${props => props.theme.spacing.inset};
`;

const SocialMediaIcon = {
    'facebook': FacebookIcon,
    'instagram': InstagramIcon,
}

const SocialLinks = ({ socialLinks=[] }) => {
    return (
        <List>
            {socialLinks.map(({ title = '', link = '', type='' }, i) => {
                let Icon = SocialMediaIcon[type];
                return (
                    <ListLink key={`social-link-${i}`} target="_blank" rel="noopener" href={link} title={title}>
                        {Icon && <Icon iconColor="secondary" />}
                    </ListLink>
                )
            })}
        </List>
    );
}
export default SocialLinks;
