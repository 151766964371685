export const BASE_URL = 'https://italoarubano.com/';
export const ENDPOINT = 'italo-api/v1';

const handleResponse = async (response) => {
    const result = await response.json();
    if (result.type === 'error') {
        let error = new Error(result.errorMessage)
        error.code = result.errorCode;
        throw error;
    }
    else {
        return result.data;
    }
}

export const getWebsiteInfo = async () => {
    const url = `${BASE_URL}wp-json/${ENDPOINT}/website-info`;
    const response = await fetch(url);
    return handleResponse(response);
}

export const getPage = async (page = '') => {
    const response = await fetch(`${BASE_URL}wp-json/${ENDPOINT}/page/${page}`);
    return handleResponse(response);
}
export const getObjectsByType = async (type = '', options = {}) => {
    const perPage = options.perPage || -1;
    const offset = (options.page || 0) * perPage;
    let query = `offset=${offset}&posts_per_page=${perPage}`;
    if (options.id) query += `&p=${options.id}`;
    if (options.slug) query += `&name=${options.slug}`;
    if (options.category) query += `&cat=${options.category}`;
    if (options.filter) query += `&meta_key=${options.filter.by}&meta_value=${options.filter.value}`;
    query += options.year ? `&year=${options.year}` : '';
    const response = await fetch(`${BASE_URL}wp-json/${ENDPOINT}/objects/${type}?${query}`);
    return handleResponse(response);
}

export const getAttachment = async (imgID = 0, size = 'thumbnail') => {
    if (imgID !== undefined && imgID !== '') {
        const response = await fetch(`${BASE_URL}wp-json/${ENDPOINT}/attachment/${imgID}/${size}`)
        return handleResponse(response);
    }
    return {};
}

export const getAttachments = async (imgIDs = [], size = 'thumbnail') => {
    if (imgIDs !== undefined && imgIDs.length > 0) {
        const query = imgIDs.map(id => `include-ids[]=${id}&`).join('');
        const response = await fetch(`${BASE_URL}wp-json/${ENDPOINT}/attachments/${size}?${query.slice(0, -1)}`)
        return handleResponse(response);
    }
    return {};
}