import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
    display:block;
    max-width:100%;
    overflow:hidden;
    border-radius:5px 5px 0 0;
    width:100%;
`;

const ImageContent = ({image}) => <Image src={image['wpmudev-cdn-url']} alt={image.alt} />
export default ImageContent;