import React from 'react';
import styled from 'styled-components';
import Hero from '../../components/hero/';
import Title from '../../components/title/';
import Paragraph from '../../components/paragraph/';
import Button from '../../components/button/';
import CategoryCards from '../../components/cards/categoriesCard';

const Main = styled.main`
    margin-top:55px;
    background-color:${props => props.theme.colors[props.bgColor]};
`;

const Container = styled.div`
    margin-bottom:${props => props.theme.spacing.doubleInset};
`;

const MainArea = ({ dynamic_content=[] }) => {
    return (
        <Main bgColor="defaultQuarterly">
            <Container>
                {dynamic_content.map(({  acf_fc_layout, ...contentBlock }, j) => {
                    if (acf_fc_layout === "heroContent") return <Hero key={j} {...contentBlock} />
                    if (acf_fc_layout === "titleContent") return <Title key={j} {...contentBlock} />
                    if (acf_fc_layout === "paragraphContent") return <Paragraph key={j} {...contentBlock} />
                    if (acf_fc_layout === "categoriesContent") return <CategoryCards key={j} {...contentBlock} />
                    if (acf_fc_layout === "buttonContent") return <Button key={j} {...contentBlock} />
                    return null;
                })}  
            </Container>
        </Main>
    );
}
export default MainArea;
