import React from 'react';
import styled from 'styled-components';
import { device } from '../../utils/device';

const Container = styled.div`
    height: 100%;

    margin-bottom: 15px;
    @media ${device.tablet} {
        margin-bottom: 30px;
    }
`;

const VideoWrapper = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    margin-bottom: 0;
`;

const VideoYoutube = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media ${device.tablet} {
        min-height: ${(p)=>[p.tooSmall?"unset":"300px"]};
    }
	@media ${device.laptopL} {
        min-height: ${(p)=>[p.tooSmall?"unset":"500px"]};
    }
`;

export default function Video({ radio, videoFile /*, altVid */, ytId, tooSmall }) {
    return (
        <Container>
            <VideoWrapper>
                {radio === 'Youtube ID' ? (
                    <VideoYoutube
                        title={`youtube-video-${ytId}`}
                        src={`https://www.youtube.com/embed/${ytId}`}
                        frameBorder='0'
                        allow='autoplay; encrypted-media'
                        allowFullScreen
						tooSmall={tooSmall}
                    />
                ) : (
                    <video max-width='100%' width='100%' height='auto'>
                        <source
                            src={videoFile.url}
                            type={videoFile.mime_type}
                        />
                        {/* <source src={altVid.url} type={altVid.mime_type} /> */}
                        Your browser does not support the video tag.
                    </video>
                )}
            </VideoWrapper>
        </Container>
    );
}
