import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
    colors: {
        defaultPrimary      : "#1d2126", // black
        defaultSecondary    : "#ffffff", // white
        defaultTertiary     : "#999999", // lightgrey
        defaultQuarterly    : "#f5f5f7",
        primary             : "#5171B5", // blue
        secondary           : "#5D7ABA", // purple
        tertiary            : "#F7B201", // yellow
        quaternary          : "#5A5A5A", // dark gray
        warning             : "#990000", // red
    },
    boxShadow: {
        greyShadow  : '0px 2px 3px #00000029',
        noShadow    : 'none',
        innerShadow : 'inset 0px 0px 5px 0px #00000029'
    },
    fonts: {
        firstFont  : "Prompt",
        secondFont : "Open Sans",
    },
    fontWeight: {
        superLight : "100",
        mediumLight : "200",
        light : "300",
        regular : "400",
        medium : "500",
        bold : "600",
        mediumBold : "700",
        black : "800",
        blackBold : "900",
    },
    fontSizes: {
        small   : "14px",
        medium  : "28px",
        large   : "42px"
    },
    spacing: {
        inset       : "15px",
        doubleInset : "30px",
        tripleInset : "60px",
        quartelyInset : "90px",
        megaInset : "120px"
    }
}

const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme; 