import React from 'react';
import styled from 'styled-components';
import {
    Link
} from 'react-router-dom';
import ChevronRightIcon from '../../icons/chevronRight';
import Image from '../../components/image/productImage';

const CardItem = styled.div`
    position:relative;
    margin-bottom:${props => props.theme.spacing.inset};
    @media (min-width: 1281px) {
        margin-right:15px;
        width:calc( calc( 1280px - 15px ) / 2);

        &:nth-child(2n+0) {
            margin-right:0;
        }
    }

    @media(max-width:1280px) {
        margin-right:15px;
        width:calc( calc( 100vw - 45px ) / 2);
        &:nth-child(2n+0) {
            margin-right:0;
        }
    }

    @media(max-width:1000px) {
        width:calc( 100vw - 30px );
        margin-right:0;
    }
`;

const Card = styled(Link)`
    text-decoration:none;
    display:flex;
    flex-direction:row;
    padding:${props => props.theme.spacing.inset};
    background-color:${props => props.theme.colors[props.bgcolor]};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius:5px 5px;
    align-items: stretch;
    justify-content:space-between;
    cursor:pointer;
    height:calc( calc( 72px * 2 ) + 30px );

    &:hover {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);

        svg {
            color:${props => props.theme.colors.tertiary};
            transform:rotate(360deg);
        }
    }
    @media(max-width:480px) {
        height:auto;
    }
`;


const ImageContainer = styled.div`
    width:150px;

    @media(max-width:480px) {
        width:70px;
    }
`;

const Content = styled.div`
    padding-left:15px;
    flex:60%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    @media(max-width:480px) {
        padding-left:15px;
        padding-right:15px;
    }
`;

const Icon = styled(ChevronRightIcon)`
    flex:10%;
    align-self:center;
    svg {
        width:25px;
        height:25px;
        @media(max-width:480px) {
            width:15px;
            height:15px;
        }
    }
`;

const Title = styled.h2`
    color:${props => props.theme.colors[props.fontColor]};
    font-size:12px;
    line-height:18px;
    font-weight: 700;
    margin-bottom:0;
`;

const SubContent = styled.div`
    display:flex;
    flex-direction:row;
    font-size: 11px;
    line-height:18px;
    font-weight:600;
    color:${props => props.theme.colors.defaultPrimary};
`;

const Category = styled.span`
    color:${props => props.theme.colors[props.fontColor]};
    font-size: 11px;
    text-transform:uppercase;
    line-height:18px;
    font-weight:600;
`;

const Price = styled.span`
    color:${props => props.theme.colors[props.fontColor]};
    font-weight:700;
    font-size: 11px;
    text-transform:uppercase;
    line-height:18px;
    padding-right:8px;
`;

const Size = styled.span`
    color:${props => props.theme.colors[props.fontColor]};
    font-size: 11px;
    text-transform:uppercase;
    line-height:18px;
    font-weight:700;
    padding-left:8px;
`;

const Description = styled.div`
    color:${props => props.theme.colors[props.fontColor]};

  p {
    color:${props => props.theme.colors[props.fontColor]};
    font-size: 11px;
    line-height:18px;
    font-weight:400;
    margin-bottom: 0px;
  }
`;

// const CartIconButton = styled.div`
//     background-color:${props => props.theme.colors.tertiary};
//     border-radius:0 0 5px 5px;
//     color:black;
//     padding:${props => props.theme.spacing.inset};
//     display:flex;
//     font-size:12px;
//     font-weight:${props => props.theme.fontWeight.mediumBold};
//     align-items:center;
//     justify-content:center;
//     cursor: pointer;
//     transition:all 0.3s linear;

//     &:hover {
//         svg {
//             margin-left:8px;
//         }
//     }
// `;

const SingleCardProductArea = product => {
    const {
        ID,
        post_name,
        name,
        description,
        imageAmbient,
        imageProduct,
        type,
        size,
        price,
    } = product;
    if (!type) {
        return null
    }
    return (
        <CardItem>
            <Card id={ID} to={`/product/${post_name}`} bgcolor="defaultSecondary">
                <ImageContainer>
                    <Image image={imageAmbient === false ? imageProduct : imageAmbient} />
                </ImageContainer>
                <Content>
                    <Title fontColor="defaultPrimary">{name}</Title>
                    <SubContent>
                        <Price fontColor="defaultPrimary">Awg. {price}</Price> - <Size fontColor="defaultPrimary">{size}</Size>
                    </SubContent>
                    <Description fontColor="quaternary" className="description" dangerouslySetInnerHTML={{ __html: description }} />
                    <Category fontColor="secondary">{type.label}</Category>
                </Content>
                <Icon fontColor="defaultPrimary" />
            </Card>
            {/* <CartIconButton>
                Add to Cart <CartIcon iconColor="defaultPrimary" />
            </CartIconButton> */}
        </CardItem>
    )
}

export default SingleCardProductArea;
