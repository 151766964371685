import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    padding-left:${props => props.theme.spacing.inset};
    padding-right:${props => props.theme.spacing.inset};

    @media (min-width: 1281px) {
        width:1280px;
        margin-left:auto;
        margin-right:auto;
        padding:0;
    }
`;

const Title = styled.h1`
    flex:100%;
    margin-bottom:0;
    color:${props => props.theme.colors[props.fontColor]};
`;

const TitleArea = ({title}) => {
    return (
        <Container>
            <Title fontColor="secondary">{title}</Title>
        </Container>
    );
}
export default TitleArea;
