import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getObjectsByType } from '../../utils/data';
import Loading from '../../components/loading';
import WebsiteError from '../../components/website-error';
import Header from './header';
import Description from './description';
import GalleryArea from './gallery';
import OtherProducts from './otherProducts';

const Container = styled.main`
    margin-top: 55px;
    background-color: ${(props) => props.theme.colors[props.bgColor]};

    @media (min-width: 1281px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
    @media (max-width: 1280px) {
        width: 100%;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: ${(props) => props.theme.spacing.megaInset};
    padding-left: ${(props) => props.theme.spacing.megaInset};

    @media (max-width: 1281px) {
        padding-right: ${(props) => props.theme.spacing.inset};
        padding-left: ${(props) => props.theme.spacing.inset};
    }
`;

const SingleProductArea = ({ addOrUpdateCartItem }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [product, setProduct] = useState({});
    const [posts, setPosts] = useState([]);

    //for getting colors of items
    const [colors, setColors] = useState([]);
    const [productColors, setProductColors] = useState([]);

    //added this outside product was not rendering on new item click
    var url = window.location.href.replace(/\/$/, '');

    useEffect(() => {
        async function fetchData() {
            var url = window.location.href.replace(/\/$/, '');
            var newSlug = url.substring(url.lastIndexOf('/') + 1);

            setLoading(true);
            try {
                const product = await getObjectsByType('product', { slug: newSlug })
                setProduct(product[0]);
                setColors(product[0].availableColors.colors)
            } catch (error) {
                setError(error);
            }
            setLoading(false);
            try {
                const res = await getObjectsByType('product', { perPage: 2 });
                if (res.status !== 404) {
                    setPosts(res);
                }
            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
    }, [url]);

    async function getProductData(id) {
        try {
            const productData = await getObjectsByType('product', { id })
            return productData[0] || {};
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }

    useEffect(() => {
        async function fetchStorageItemsData() {
            let colorItems = [];
            for (let i = 0; i < colors.length; i++) {
                let productData = await getProductData(colors[i]);
                colorItems.push(productData);
            }
            setProductColors(colorItems);
        }

        fetchStorageItemsData();
    }, [colors]);

    if (loading) {
        return <Loading />;
    } else if (error) {
        return <WebsiteError error={error} />;
    }
    const { ...props } = product;

    return (
        <Container bgColor='defaultQuarterly'>
            <Header addOrUpdateCartItem={addOrUpdateCartItem} {...props} />
            <Row>
                <Description productColors={productColors} {...props} />
                <GalleryArea {...props?.gallery} />
                <OtherProducts posts={posts} />
            </Row>
        </Container>
    );
};
export default SingleProductArea;
