import React, { Component } from 'react';
import styled from 'styled-components';
import InfoForm from '../form/informationForm';

const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:${props => props.theme.spacing.inset};
    margin-bottom:${props => props.theme.spacing.doubleInset};
`;

export default class CheckoutForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            registrationLoading: false,
            showFormError: false,
            showFormSuccess: false,
        };
    }

    onRegister = async (results) => {
        let errors = results.validatedResults.map(r => r.error).filter(e => e != null);
        if (errors.length > 0) {
            console.log(`${errors.length} errors`);
            this.setState({ showFormError: true, showFormSuccess: false });
        }
        else {
            const grecaptcha = window.grecaptcha;
            if (!grecaptcha) {
                console.log(`recaptcha object not defined`);
                return;
            }
            const self = this;
            try {
                let reCaptchaAction = 'homepage';
                grecaptcha.ready(function () {
                    grecaptcha.execute('6Le19-cUAAAAAGmNYPRtqcIipE04lnvkvn71z-KP', { action: reCaptchaAction }).then(function (token) {

                        let { ajaxurl, ajax_nonce } = window.ajaxVars || {};
                        if (!ajaxurl || !ajax_nonce) {
                            console.log(`incorrect nonce or url ${ajax_nonce}, ${ajaxurl}`);
                            self.setState({ showFormError: true, showFormSuccess: false });
                            return;
                        }
                        self.setState({ showFormError: false, showFormSuccess: true });

                        let info = results.validatedResults.reduce((dict, entry) => {
                            dict[entry.id] = entry.value;
                            return dict;
                        }, {});
                        let allResults = { ...info, ...results.otherResults };

                        let formData = new FormData();
                        for (const property in allResults) {
                            formData.set(property, allResults[property]);
                        }
                        //const fileField = document.querySelector('input[type="file"]');
                        //formData.set('cv', fileField.files[0]);
                        formData.set('action', 'handle_online_order_form');
                        self.props.cartItems.forEach(prodduct => formData.append('products[]', encodeURIComponent(JSON.stringify(prodduct))));
                        const total = self.props.cartItems.map((item, i) => item.quantity * item.price).reduce((a, b) => a + b, 0).toFixed(2)
                        formData.set('total', total);
                        formData.set('security', ajax_nonce);
                        formData.set('token', token);
                        formData.set('recaptcha-action', reCaptchaAction);
                        fetch(ajaxurl, {
                            method: 'POST',
                            body: formData
                        })
                            .then(_ => {
                                localStorage.clear();
                                window.location.href = '/thank-you';
                            })
                            .then(async (responseJson) => {
                                console.log(responseJson);
                            })
                            .catch((error) => {
                                console.error(error);
                            })
                            .finally(_ => {
                                self.setState({ validating: false });
                            });
                    });
                });
            }
            catch (error) {
                console.log('Error executing grecaptcha');
                console.log(error);
            }
        }
    }

    render() {
        let { showFormError, showFormSuccess, registrationLoading } = this.state;
        const {cartItems, addOrUpdateCartItem, removeItemFromCart } = this.props;
        return (
            <Container>
                <InfoForm
                    register={this.onRegister}
                    info={this.info}
                    showFormError={showFormError}
                    showFormSuccess={showFormSuccess}
                    registrationLoading={registrationLoading}
                    addOrUpdateCartItem={addOrUpdateCartItem}
                    removeItemFromCart={removeItemFromCart}
                    cartItems={cartItems}
                />
            </Container>
        );
    }
}
