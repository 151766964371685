import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Cart from '../../components/cart/';
import { BASE_URL } from '../../utils/data';

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
`;

const ListItem = styled.li`
    margin-left: 15px;
`;

const LinkItem = styled(NavLink)`
    font-size: 12px;
    line-height: 18px;
    font-weight: ${(props) => props.theme.fontWeight.mediumBold};
    color: ${(props) => props.theme.colors.defaultPrimary};
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        svg {
            color: ${(props) => props.theme.colors.tertiary};
        }
    }
    &.active {
        color: ${(props) => props.theme.colors.tertiary};
    }
`;

const Navigation = ({
    menuItems = [],
    cartItems = [],
    removeItemFromCart,
    addOrUpdateCartItem,
}) => {
    
    return (
        <List>
            {menuItems.map(({ title = '', pageLink = '' }, i) => (
                <ListItem key={`menu-item-${i}`}>
                    <LinkItem
                        exact
                        title={title}
                        to={`/${pageLink.replace(BASE_URL, '')}`}
                        activeClassName='active'
                    >
                        {title}
                    </LinkItem>
                </ListItem>
            ))}
            <ListItem>
                <Cart
                    items={cartItems}
                    removeItemFromCart={removeItemFromCart}
                    addOrUpdateCartItem={addOrUpdateCartItem}
                />
            </ListItem>
        </List>
    );
};
export default Navigation;
