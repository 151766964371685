export const getStorageItem = itemId => {
    const storageItem = localStorage.getItem(itemId);
    return JSON.parse(storageItem);
}

export const setStorageItem = (item, id) => {
    return localStorage.setItem(id, JSON.stringify(item));
}

export const removeStorageItem = itemId => {
    return localStorage.removeItem(itemId);
}