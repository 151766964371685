import React from 'react';
import styled from 'styled-components';
import SubscribeArea from '../subscribe/';
import LogoProject from '../../svg/italo.svg';
import SocialLinks from '../../components/lists/social-links';
import NawInformation from '../../components/lists/naw-info';

const Aside = styled.aside`
    width:100%;
`;

const ContactArea = styled.div`
    background-color:${props => props.theme.colors[props.bgColor]};
    padding-top:${props => props.theme.spacing.inset};
    padding-bottom:${props => props.theme.spacing.doubleInset};
    border-top:1px solid rgba(0,0,0,0.1);
    display:flex;
    align-items:center;
    flex-direction:column;
    &:last-child {
        margin-bottom:0;
    }
`;

const Image = styled.img`
    width:175px;
    height:auto;
    margin-bottom:${props => props.theme.spacing.inset};
`;

const AsideArea = ({footer=[]}) => {
    return (
        <Aside>
            <ContactArea bgColor="defaultSecondary">
                <Image src={LogoProject} alt="Italo Arubano" />
                <SocialLinks socialLinks={footer.socialLinks} />
                <NawInformation nawInformation={footer.nawInformation} />
            </ContactArea>
            <SubscribeArea subscribe={footer.subscribe} />
        </Aside>
    );
}
export default AsideArea;
