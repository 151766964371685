import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getAttachments } from '../../utils/data';
import {
    Link
} from 'react-router-dom';
import SingleCard from '../../components/cards/singleCard';

const Row = styled.div`
    display:flex;
    padding-top:${props => props.theme.spacing.doubleInset};
    padding-bottom:${props => props.theme.spacing.doubleInset};

    @media (max-width: 1281px){
        flex-direction:column;
    }
`;

const ExtraInformation = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    background-color:${props => props.theme.colors[props.bgColor]};
    padding:${props => props.theme.spacing.inset};
    padding-top:${props => props.theme.spacing.tripleInset};
    border-bottom:0;
    border-top:1px;
    border-left:0;
    border-right:0;
    border-style:solid;
    border-color:${props => props.theme.colors.defaultTertiary};
`;

const Strong = styled.strong`
    color:${props => props.theme.colors[props.fontColor]};
    font-size:${props => props.theme.fontSizes[props.fontSize]};
    line-height:18px;
    font-weight: 700;
    margin-bottom:${props => props.theme.spacing.inset};
    margin-top:0;
`;

const Button = styled.div`
    flex:50%;
    border:0;
    width:15%;
    color:${props => props.theme.colors[props.fontcolor]};
    background-color:${props => props.theme.colors[props.bgColor]};
    font-weight:${props => props.theme.fontWeight.mediumBold};
    display:flex;
    text-align:center;
    justify-content:center;
    border-radius:3px;
    padding:${props => props.theme.spacing.inset};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    text-transform:capitalize;
    margin-bottom:0;
    cursor:pointer;
    text-decoration:none;
    transition:all 0.3s linear;

    &:hover {
        background-color:${props => props.theme.colors.primary};
    }
`;

const HyperLink = styled(Link)`
    display:flex;
    width:25%;
    color:${props => props.theme.colors.defaultPrimary};
    text-decoration:none;
    padding:0;
    margin:0;

    @media (max-width: 1281px){
        width:100%;
    }
`;

const OtherProduct = ({ posts = [] }) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const category = urlParams.get('cat') || 'all';
    const cat = useState(category);
    const [newPosts, setnewPosts] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const size = 200;
            try {
                const imageProductIDs = posts.map(({ imageProduct }) => imageProduct).filter(image => image !== false && image !== undefined);
                const nrOfProductIDsChunks = Math.ceil(imageProductIDs.length / size);
                for (let i = 0; i < nrOfProductIDsChunks; i++) {
                    const chunkIDs = imageProductIDs.splice(0, size)
                    const productImages = await getAttachments(chunkIDs, 'medium');
                    if (productImages && productImages.length > 0) {
                        productImages.forEach(image => {
                            for (let i = 0; i < posts.length; i++) {
                                if (posts[i].imageProduct === parseInt(image.ID)) {
                                    posts[i].imageProduct = image;
                                }
                            }
                        });
                    }
                }

                const ambientImagesIDs = posts.map(({ imageAmbient }) => imageAmbient).filter(image => image !== false && image !== undefined);

                const nrOfambientImagesIDsChunks = Math.ceil(ambientImagesIDs.length / size);
                for (let i = 0; i < nrOfambientImagesIDsChunks; i++) {
                    const chunkIDs = ambientImagesIDs.splice(0, size)
                    const ambientImages = await getAttachments(chunkIDs, 'medium');
                    if (ambientImages && ambientImages.length > 0) {
                        ambientImages.forEach(image => {
                            for (let i = 0; i < posts.length; i++) {
                                if (posts[i].imageAmbient === parseInt(image.ID)) {
                                    posts[i].imageAmbient = image;
                                }
                            }
                        });
                    }
                }
                setnewPosts(posts);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchData();
    }, [posts]);
    return (
        <ExtraInformation bgColor="defaultQuarterly">
            <Strong fontColor="primary" fontSize="medium">Other Products</Strong>
            <Row>
                {
                    newPosts.length > 0 && newPosts.filter(({ type }) => type.value).map((productItem, i) =>
                        // <Link key={`other-products-link-${i}`} to={`/${productItem.post_type}/${productItem.post_name}`} style={{textDecoration: 'none'}}>
                        <SingleCard key={`other-products-link-${i}`} currentCategory={cat} {...productItem} />
                        // </Link>
                    )
                }
            </Row>
            <HyperLink to="/products">
                <Button bgColor="tertiary" fontColor="defaultSecondary" >All products</Button>
            </HyperLink>
        </ExtraInformation>
    )
}
export default OtherProduct;
