import Image from './image';
import TwoImages from './two-images';
import ThreeImages from './three-images';
import Video from './video';
import TwoImagesStretch from './two-images-stretch';

const contentBlockTemplates = {
    image: Image,
    twoImages: TwoImages,
    twoImagesStretch: TwoImagesStretch,
    threeImages: ThreeImages,
    video: Video,
};

export default function GalleryContentBlocks({ acf_fc_layout, ...props }) {
    const Template = contentBlockTemplates[acf_fc_layout]
        ? contentBlockTemplates[acf_fc_layout]
        : contentBlockTemplates['image'];
    return <Template {...props} />;
}
