import React, { useState, useEffect } from 'react';
import Theme from './theme/';
import styled, { createGlobalStyle } from 'styled-components';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { getWebsiteInfo, getObjectsByType } from './utils/data';
import ScrolltoTop from './components/scrollToTop/';
import Header from './components/header-area/';
import Aside from './components/aside/';
import Footer from './components/footer/';
import Loading from './components/loading';
import Website from './components/website/';
import WebsiteError from './components/website-error';
import { getStorageItem, setStorageItem } from './utils/localStorage';

const GlobalStyles = createGlobalStyle`
    *,
    *:after,
    *:before {
        box-sizing:border-box;
    }
    
    body {
        margin:0;
        padding:0;
        font-family: ${props => props.theme.fonts.secondFont};
        color: ${props => props.theme.colors.defaultPrimary};
        background-color: ${props => props.theme.colors.defaultQuarterly};
        font-size: ${props => props.theme.fontSizes.small};
        font-weight: ${props => props.theme.fontWeight.regular};
        font-display:fallback;
    }
    h1, h2, h3, h4, h5, h6, p {
        font-family: ${props => props.theme.fonts.firstFont};
        margin-top:0;
        margin-bottom:${props => props.theme.spacing.inset};
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const App = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [website, setWebsite] = useState({});
    const [cartItems, setCartItems] = useState([]);

    async function getProductData(id) {
        try {
            const productData = await getObjectsByType('product', { id })
            return productData[0] || {};
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const website = await getWebsiteInfo();
                setWebsite(website);
            }
            catch (error) {
                setError(error);
            }
            setLoading(false);
        }

        async function fetchStorageItemsData() {
            // TODO: build timer for cart items (eq. 30 days);
            const cartStorageItems = getStorageItem('cart-items') || [];
            let cartItemsData = [];
            for (let i = 0; i < cartStorageItems.length; i++) {
                let productData = await getProductData(cartStorageItems[i].itemId);
                productData.quantity = cartStorageItems[i].quantity;

                cartItemsData.push(productData);

            }
            setCartItems(cartItemsData);
        }

        fetchData();
        fetchStorageItemsData();
    }, []);

    website.addOrUpdateCartItem = async (itemId, quantity) => {
        let cartStorageItems = getStorageItem('cart-items') || [];

        let exists = false;
        for (let i = 0; i < cartStorageItems.length; i++) {
            if (cartStorageItems[i].itemId === itemId) {
                exists = true;
                cartStorageItems[i].quantity = quantity;
                break;
            }
        }
        if (!exists) {
            cartStorageItems.push({ itemId, quantity });
        }
        setStorageItem(cartStorageItems, 'cart-items');

        exists = false;
        let cartItemsData = [...cartItems];
        for (let i = 0; i < cartItemsData.length; i++) {
            if (cartItemsData[i].ID === itemId) {
                exists = true;
                cartItemsData[i].quantity = quantity;
                break;
            }
        }
        if (!exists) {
            let productData = await getProductData(itemId);
            productData.quantity = quantity;
            cartItemsData.push(productData);
        }
        setCartItems(cartItemsData);

    }

    website.removeItemFromCart = itemId => {
        let cartStorageItems = getStorageItem('cart-items') || [];
        // perform splice on storage item
        for (let i = 0; i < cartStorageItems.length; i++) {
            if (cartStorageItems[i].itemId === itemId) {
                cartStorageItems.splice(i, 1);
                //update storage
                setStorageItem(cartStorageItems, 'cart-items');
                break;
            }
        }

        // perform splice on cart items
        const cartItemsData = [...cartItems];
        for (let i = 0; i < cartItemsData.length; i++) {
            if (cartItemsData[i].ID === itemId) {
                cartItemsData.splice(i, 1);
                //update cart
                setCartItems(cartItemsData);
                break;
            }
        }

        // hide current cart item from cart
        const currentCartItem = document.querySelector(`#product-${itemId}`);
        currentCartItem.style.display = 'none';
    }

    if (loading) {
        return <Loading />;
    }
    else if (error) {
        return <WebsiteError error={error} />
    }
    else {
        return (
            <Router>
                <Theme>
                    <Container>
                        <ScrolltoTop />
                        <GlobalStyles />
                        <Header {...website} cartItems={cartItems} />
                        <Website website={website} cartItems={cartItems} />
                        <Aside {...website} />
                        <Footer />
                    </Container>
                </Theme>
            </Router>
        );
    }
}

export default App;