import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getAttachment } from '../../utils/data';
import Loading from '../loading';

const Hero = styled.div`
    display:flex;
    flex-direction:row;
    position:relative;
    height:auto;
    width:100%;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    margin-bottom:${props => props.theme.spacing.inset};

    @media (min-width: 1281px) {
        width:1280px;
        margin-left:auto;
        margin-right:auto;
    }
`;

const Image = styled.img`
    display:block;
    max-width:100%;
`;

const HeroArea = ({hero}) => {
    const [imgData, setThumbnailImage] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await getAttachment(hero.ID, 'full');
                setThumbnailImage(res);
                setLoading(false);
            }
            catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [hero]);
    if (loading) return <Loading />;
    return (
        <Hero>
            <Image src={imgData['wpmudev-cdn-url']} alt={hero.alt} />
        </Hero>
    );
}
export default HeroArea;
