import React, { useState } from 'react';
import styled from 'styled-components';
import MinusIcon from '../../icons/minus';
import AddIcon from '../../icons/add';
import RemoveIcon from '../../icons/remove';
import BGImage from '../image/bg-image';

const Card = styled.div`
    text-decoration: none;
    display: flex;
    flex-direction: row;
    padding: ${(props) => props.theme.spacing.inset};
    background-color: ${(props) => props.theme.colors[props.bgcolor]};
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    align-items: center;
    position: relative;
`;

const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    justify-content: center;
`;

const Title = styled.h2`
    color: ${(props) => props.theme.colors[props.fontColor]};
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 0;
`;

const SubContent = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 11px;
    line-height: 18px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.defaultPrimary};
`;

const Category = styled.span`
    color: ${(props) => props.theme.colors[props.fontColor]};
    font-size: 11px;
    text-transform: uppercase;
    line-height: 18px;
    font-weight: 600;
`;

const Price = styled.span`
    color: ${(props) => props.theme.colors[props.fontColor]};
    font-size: 11px;
    text-transform: uppercase;
    line-height: 18px;
    font-weight: 700;
`;

const Size = styled.span`
    color: ${(props) => props.theme.colors[props.fontColor]};
    font-size: 11px;
    text-transform: uppercase;
    line-height: 18px;
    font-weight: 700;
`;

const Description = styled.div`
    color: ${(props) => props.theme.colors[props.fontColor]};
    font-size: 11px;
    text-transform: uppercase;
    line-height: 18px;
    font-weight: 700;
    p {
        margin-bottom: 0px;
    }
`;

const Actions = styled.div`
    flex: 0 0 100px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`;

const FakeInput = styled.div`
    border: 0;
    font-weight: 700;
    color: ${(props) => props.theme.colors.defaultPrimary};
    font-size: 12px;
    width: 20px;
    text-align: center;
`;

const Input = styled.input`
    border: 0;
    font-weight: 700;
    color: ${(props) => props.theme.colors.defaultPrimary};
    font-size: 12px;
    width: 20px;
    text-align: center;
    display: none;
`;

const QtyButton = styled.button`
    cursor: pointer;
    border: 0;
    background-color: transparent;
`;

const AddQuantity = styled(AddIcon)`
    cursor: pointer;
    pointer-events: none;
    width: 15px;
    height: 15px;
`;

const RemoveQuantity = styled(MinusIcon)`
    cursor: pointer;
    pointer-events: none;
    width: 15px;
    height: 15px;
`;

const DeleteIconContainer = styled.div`
    cursor: pointer;
`;

const DeleteIcon = styled(RemoveIcon)`
    width: 15px;
    min-width: 15px;
    height: 15px;
    min-height: 15px;
    position: absolute;
    top: 15px;
    right: 14px;
`;

const CartProductArea = ({
    ID,
    quantity = 0,
    imageAmbient,
    imageProduct,
    name,
    price,
    unitDescription,
    size,
    description,
    type = '',
    removeItemFromCart,
    addOrUpdateCartItem,
}) => {
    const [count, setCount] = useState(quantity);

    const updateCount = (NewQuantity, event) => {
        event.preventDefault();
        event.stopPropagation();
        setCount(NewQuantity);
        addOrUpdateCartItem(ID, NewQuantity);
    };

    if (quantity !== count) {
        setCount(quantity);
    }
    return (
        <Card id={`product-${ID}`} bgcolor='defaultSecondary'>
            <BGImage
                imgID={imageAmbient === false ? imageProduct : imageAmbient}
                size={'full'}
            />
            <Content>
                <Title fontColor='defaultPrimary'>{name}</Title>
                <SubContent>
                    <Price fontColor='tertiary'>{`Awg. ${price} / ${unitDescription}`}</Price>
                    <Size fontColor='defaultPrimary'>{size}</Size>
                    <Description
                        fontColor='defaultPrimary'
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </SubContent>
                <Category fontColor='secondary'>{type.label}</Category>
            </Content>
            <Actions>
                <QtyButton
                    onClick={(event) =>
                        updateCount(count > 0 ? count - 1 : 0, event)
                    }
                >
                    <RemoveQuantity
                        className='actionIcon'
                        iconColor='defaultTertiary'
                    />
                </QtyButton>
                <FakeInput>{count}</FakeInput>
                <Input
                    type='hidden'
                    value={count}
                    min='0'
                    name='quantity'
                    step='1'
                    onChange={(e) => updateCount(parseInt(e.target.value))}
                />
                <QtyButton onClick={(event) => updateCount(count + 1, event)}>
                    <AddQuantity
                        className='actionIcon'
                        iconColor='defaultTertiary'
                    />
                </QtyButton>
            </Actions>
            <Price fontColor='tertiary'>{(price * count).toFixed(2)}</Price>
            <DeleteIconContainer
                onClick={(event) =>
                    removeItemFromCart(ID, event.stopPropagation())
                }
            >
                <DeleteIcon iconColor='warning' />
            </DeleteIconContainer>
        </Card>
    );
};
export default CartProductArea;
