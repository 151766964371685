import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    min-width: 20px;
    width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    color: grey;
    user-select: none;
`;

const SVG = styled.svg`
    width: 100%;
    height: 100%;
    color:${props => props.theme.colors[props.fontColor]};
`;

const MinusIcon = ({ className, iconColor }) => (
    <Container className={className} >
        <SVG fontColor={iconColor} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus-circle" className="svg-inline--fa fa-minus-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z"/></SVG>
    </Container>
);

export default MinusIcon;