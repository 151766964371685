import React, {Component} from 'react';
import PropTypes from 'prop-types';

export const MapToOptions = options => options.map( ({text, ...rest}) => <option {...rest} >{text}</option> )    
const EmptyOptionWithText = text => [{
    key:'-', 
    value: '', 
    text:text,
    disabled: false
}];

export default class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        }

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onGetValidateValues = this.onGetValidateValues.bind(this);
        this.onValidated = this.onValidated.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.validator !== this.props.validator) {
            prevProps.validator.setComponentGetValidateValues(null);
            prevProps.validator.setComponentOnValidated(null);
            prevProps.validator.componentHasUnmounted();
            this.props.validator.setComponentGetValidateValues(this.onGetValidateValues);
            this.props.validator.setComponentOnValidated(this.onValidated);
            this.props.validator.componentHasMounted();
        }
    }

    componentWillMount() {
        if (this.props.validator) {
            this.props.validator.setComponentGetValidateValues(this.onGetValidateValues);
            this.props.validator.setComponentOnValidated(this.onValidated);
            this.props.validator.componentHasMounted();
        }
    }
    componentWillUnmount() {
        if (this.props.validator) {
            this.props.validator.setComponentGetValidateValues(null);
            this.props.validator.setComponentOnValidated(null);
            this.props.validator.componentHasUnmounted();
        }
    }

    onGetValidateValues() {
        return {id: this.props.id, value: this.state.value};
    }

    onValidated(results) {
        this.setState(results);
    }

    onChange(event) {
        this.setState({value: event.target.value});
        if (this.props.onChange) {
            this.props.onChange(event);
        }
        if (this.props.validator) {
            this.props.validator.validate(event.target.value);
        }
    }

    onBlur(event) {
        if (this.props.validator) {
            this.props.validator.validate(event.target.value);
        }
    }

    render() {
        let {id, options, empty=true, emptyText='', ...rest} = this.props;
        let {value, error} = this.state;
        var className = (error ? 'Error ':'');
        className += (!value ? 'Placeholder':'');
        return (
            <div id={id} className='Select'>
                <select 
                    className= {className}
                    value={value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    {...rest}
                >
                    {empty ? MapToOptions(EmptyOptionWithText(emptyText)) : null }
                    {MapToOptions(options)}
                </select>
                {error ? <span className='Error'>{error}</span> : null}
            </div>
        );
    }
}
Select.protoTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }).isRequired,
    )
}