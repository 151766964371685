import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Validator,
    TextField,
    TextArea,
    Select,
    SelectValidator,
    ValueSetValidator,
    EmailValidator,
} from '../../input/';
import CartProductArea from '../../components/cart/cart-product-area';

const Title = styled.h2`
    color:${props => props.theme.colors[props.fontColor]};
    font-size:15px;
    line-height:18px;
    font-weight: 400;
    margin-bottom:${props => props.theme.spacing.inset};
    margin-top:0;

    @media (max-width:1280px) {
        padding-left:${props => props.theme.spacing.inset};
        padding-right:${props => props.theme.spacing.inset};
    }
`;

const CartItems = styled.div`
    margin-bottom:${props => props.theme.spacing.inset};
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    > div {
        @media (min-width: 1281px) {
            width:calc( calc( 100% - 15px ) / 2 );
            margin-right:15px;
            margin-bottom:15px;

            &:nth-child(2n+0) {
                margin-right:0px;
            }
        }
        @media (max-width:1280px) {
            margin-right:0;
            margin-bottom:${props => props.theme.spacing.inset};
            width:100%;
        }
    }
    @media (max-width:1280px) {
        padding-left:${props => props.theme.spacing.inset};
        padding-right:${props => props.theme.spacing.inset};
    }
`;

const FormContainer = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    padding:${props => props.theme.spacing.inset};
    background-color:${props => props.theme.colors.defaultSecondary};
    border-radius:5px;
    border:1px solid rgba(0,0,0,0.05);

    @media (max-width:1280px) {
        margin-left:${props => props.theme.spacing.inset};
        margin-right:${props => props.theme.spacing.inset};
    }
`;

const Alert = styled.div`
    border:1px solid ${props => props.theme.colors.defaultTertiary};
    padding:${props => props.theme.spacing.inset};
    color:${props => props.theme.colors.defaultSecondary};
    grid-column: 1 / -1;
    background-color:${props => [props.bgColor]};
    margin-bottom:0;
`;

const FormItem = styled.div`
    width:calc( 100% );
    margin-bottom:${props => props.theme.spacing.inset};
    .Error {
        border:1px solid #990000;
        background-color:rgba(153,0,0,0.3);
    }
    span.Error {
        display:none;
        visibility:hidden;
    }

    input, textarea {
        width:100%;
        height:35px;
        padding-left:10px;
        font-size:12px;
        border:1px solid rgba(0,0,0,0.1);
        border-radius:0px;
        box-shadow:none;
        -webkit-appearance: none;
    }
    select {
        width:100%;
        height:25px;
        font-size:12px;
    }
    textarea {
        height:200px;
        padding:10px;
        border:1px solid rgba(0,0,0,0.1);
        
    }
`;

const Label = styled.label`
    color:${props => props.theme.colors.defaultPrimary};
    font-size:12px;
    line-height:24px;
    font-weight:${props => props.theme.fontWeight.mediumBold};
`;

const Button = styled.button`
    cursor:pointer;
    color:${props => props.theme.colors[props.fontColor]};
    background-color:${props => props.theme.colors[props.bgColor]};
    font-size:12px;
    line-height:18px;
    font-weight:${props => props.theme.fontWeight.light};
    letter-spacing:0.96px;
    font-weight:${props => props.theme.fontWeight.bold};
    border:0px;
    padding:${props => props.theme.spacing.inset} ${props => props.theme.spacing.tripleInset};
    width:100%;
    margin-bottom:${props => props.theme.spacing.inset};
    text-align:center;
    text-decoration:none;
    transition:all 0.2s linear;
    border-radius:3px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    &:disabled {
        background-color:#666666;
        color:#cccccc;
        cursor:not-allowed;
    }

    &:hover {
        color:${props => props.theme.colors[props.fontColorHover]};
        background-color:${props => props.theme.colors[props.bgColorHover]};
    }
`;

const Warning = styled.div`
    justify-content:center;
    width:100%;
    background-color:${props => [props.bgColor]};
    color:${props => props.theme.colors.defaultSecondary};
    font-size:12px;
    line-height:18px;
    padding:${props => props.theme.spacing.inset};
`;

const TotalContainer = styled.div`
    background-color:${props => props.theme.colors.defaultPrimary};
    color:${props => props.theme.colors.defaultSecondary};
    margin-bottom:${props => props.theme.spacing.doubleInset};
    border-radius:5px;
    padding:${props => props.theme.spacing.inset};
    display:flex;
    font-weight:${props => props.theme.fontWeight.mediumBold};
    font-size:12px;
    line-height:18px;
    justify-content:flex-end;
    position:sticky;
    top:55px;
    z-index:19;

    @media(max-width:1280px) {
        margin-left:${props => props.theme.spacing.inset};
        margin-right:${props => props.theme.spacing.inset};
        border-radius:0 0 3px 3px;
    }
`;

const TotalNumber = styled.span`
    color:${props => props.theme.colors.tertiary};
    margin-right:8px;
    margin-left:8px;
`;

const addressTypeOptions = [
    { key: 'house', value: 'house', text: 'House' },
    { key: 'appartment', value: 'appartment', text: 'Appartment' },
    { key: 'business', value: 'business', text: 'Other' },
];

const paymentTypeOptions = [
    { key: 'cashOnDelivery', value: 'cashOnDelivery', text: 'Cash on Delivery' },
    { key: 'debitCardSwipe', value: 'debitCardSwipe', text: 'Debit Card (swipe)' },
    { key: 'creditCardSwipe', value: 'creditCardSwipe', text: 'Credit Card (swipe)' },
    { key: 'bankTransfer', value: 'bankTransfer', text: 'Bank Transfer' },
];

const districtOptions = [
    { key: 'noord', value: 'noord', text: 'Noord' },
    { key: 'eagle', value: 'eagle', text: 'Eagle' },
    { key: 'tankiflip', value: 'tankiflip', text: 'Tanki Flip' },
    { key: 'tankileendert', value: 'tankileendert', text: 'Tanki Leendert' },
    { key: 'paradera', value: 'paradera', text: 'Paradera' },
    { key: 'oranjestad', value: 'oranjestad', text: 'Oranjestad' },
    { key: 'santacruz', value: 'santacruz', text: 'Santa Cruz' },
    { key: 'savaneta', value: 'savaneta', text: 'Savaneta' },
    { key: 'poschiquito', value: 'poschiquito', text: 'Pos Chiquito' },
    { key: 'sannicolas', value: 'sannicolas', text: 'San Nicolas' },
];

const InfoForm = ({ register, showFormError, showFormSuccess, registrationLoading, cartItems, addOrUpdateCartItem, removeItemFromCart }) => {
    const [formData, setFormData] = useState({});
    const validator = new Validator();
    const onRegister = e => {
        if (register) {
            const allResults = { validatedResults: validator.validResult(), otherResults: formData };
            register(allResults);
        }
        e.preventDefault();
    }

    const onHandleTypeChange = (type, e) => {
        let { value } = e.target;
        let data = { ...formData };
        data[type] = value;
        setFormData(data);
    }

    showFormError = showFormError || false;
    showFormSuccess = showFormSuccess || false;

    let sendStatus = 'initial';
    if (registrationLoading) sendStatus = 'processing';
    else if (showFormSuccess) sendStatus = 'done';

    return (
        <>
            <Title fontColor="tertiary">Your order</Title>
            <CartItems>
                {cartItems.map((item, i) =>
                    <CartProductArea
                        key={`cart-item-${item.ID}-${i}`}
                        addOrUpdateCartItem={addOrUpdateCartItem}
                        removeItemFromCart={removeItemFromCart}
                        {...item}
                    />
                )}
            </CartItems>
            <TotalContainer>
                Total: <TotalNumber>AWG. {cartItems.map((item, i) => item.quantity * item.price).reduce((a, b) => a + b, 0).toFixed(2)}</TotalNumber>
            </TotalContainer>
            <Title fontColor="tertiary">Your billing address</Title>
            <FormContainer>
                {showFormError &&
                    <FormItem>
                        <Warning bgColor='rgba(153,0,0,0.7)'>
                            Please fill in all the information below.
                        </Warning>
                    </FormItem>
                }
                <FormItem>
                    <Label htmlFor="addressType">Type of Address *</Label>
                    <Select id="addressType" value={formData['addressType']} onChange={event => onHandleTypeChange('addressType', event)} validator={new SelectValidator(validator)}
                        options={addressTypeOptions.map(({ key, value, text }) => ({
                            key: key,
                            value: value,
                            text: `${text}`,
                        }))}
                    />
                </FormItem>
                <FormItem>
                    <Label htmlFor="district">District *</Label>
                    <Select id="district" value={formData['district']} onChange={event => onHandleTypeChange('district', event)} validator={new SelectValidator(validator)}
                        options={districtOptions.map(({ key, value, text }) => ({
                            key: key,
                            value: value,
                            text: `${text}`,
                        }))}
                    />
                </FormItem>
                <FormItem>
                    <Label htmlFor="streetAddress">Address *</Label>
                    <TextField
                        id='streetAddress'
                        placeholder=''
                        validator={new ValueSetValidator(validator)}
                    />
                </FormItem>
                <FormItem>
                    <Label htmlFor="fullName">Full name *</Label>
                    <TextField
                        id='fullName'
                        placeholder=''
                        validator={new ValueSetValidator(validator)}
                    />
                </FormItem>
                <FormItem>
                    <Label htmlFor="email">Email *</Label>
                    <TextField
                        id='email'
                        placeholder=''
                        validator={new EmailValidator(validator)}
                    />
                </FormItem>
                <FormItem>
                    <Label htmlFor="telephone">Telephone *</Label>
                    <TextField
                        id='telephone'
                        placeholder=''
                        validator={new ValueSetValidator(validator)}
                    />
                </FormItem>
                <FormItem>
                    <Label htmlFor="paymentMethod">Payment method *</Label>
                    <Select id="paymentMethod" value={formData['paymentMethod']} onChange={event => onHandleTypeChange('paymentMethod', event)} validator={new SelectValidator(validator)}
                        options={paymentTypeOptions.map(({ key, value, text }) => ({
                            key: key,
                            value: value,
                            text: `${text}`,
                        }))}
                    />
                </FormItem>
                <FormItem className="textArea">
                    <Label htmlFor="message">Extra information *</Label>
                    <TextArea
                        id='message'
                        placeholder=''
                        validator={new ValueSetValidator(validator)}
                    />
                </FormItem>
                <FormItem>
                    <Button className={sendStatus} onClick={onRegister} disabled={(['processing', 'done'].includes(sendStatus))}
                        fontColor='defaultPrimary'
                        fontColorHover='tertiary'
                        bgColor='tertiary'
                        bgColorHover='defaultPrimary'
                    >
                        {sendStatus === 'initial' && 'Place my order'}
                        {sendStatus === 'processing' && <div>Submitting<span>.</span><span>.</span><span>.</span></div>}
                        {sendStatus === 'done' && 'Done!'}
                    </Button>
                    {showFormSuccess &&
                        <Alert bgColor='rgba(5,83,159,0.7)'>
                            Thank you for making your order. Italo Arubano will contact you through telephone or email.
                        </Alert>
                    }
                </FormItem>
            </FormContainer>
        </>
    );
}

export default InfoForm;
