import React from 'react';
import styled from 'styled-components';
import GalleryContentBlocks from '../../components/gallery-content-blocks';
import { device } from '../../utils/device';

const Container = styled.div`
    padding-top: ${(props) => props.theme.spacing.tripleInset};

    @media (max-width: 1281px) {
        padding-top: ${(props) => props.theme.spacing.doubleInset};
    }
`;

const Gallery = styled.div`
    display: flex;
    height: 100%;
    margin-bottom: 60px;
    flex-direction: column;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:space-between;
`;


const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
	max-height:650px;


    padding-right: 0;

    @media ${device.tablet} {
		max-height:580px;

        padding-right: ${(props) =>
        props.spacing ? (props) => props.theme.spacing[props.spacing] : ''};

        width: ${(props) => (props.width ? [props.width] : '100%')};
        & > :last-child {
            margin-bottom: 0;
        }
    }

	@media ${device.laptopL}{
		max-height:830px;

	}
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(p) => p.theme.spacing.inset};
    overflow: hidden;

    @media ${device.tablet} {
        margin-bottom: ${(p) => p.theme.spacing.doubleInset};

        flex-direction: row;
    }
`;

const Title = styled.p`
    color: ${(props) => props.theme.colors[props.fontColor]};
    font-size: ${(props) => props.theme.fontSizes[props.fontSize]};
    line-height: 18px;
    font-weight: 700;
    margin-bottom: ${(props) => props.theme.spacing.doubleInset};
    margin-top: 0;

    @media ${device.tablet} {
        margin-bottom: ${(props) => props.theme.spacing.tripleInset};
    }
    @media (max-width: 1281px) {
        padding: 0;
        display: flex;
        justify-content: center;
        font-size: 20px;
    }
`;

const Button = styled("a")`
     color:${props => props.theme.colors[props.fontcolor]};
    background-color:${props => props.theme.colors[props.bgcolor]};
    height:50px;
    max-width:250px;
    flex: 50%;
    border: 0;
    font-weight: ${(props) => props.theme.fontWeight.mediumBold};
    font-size:16px;
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: 3px;
    padding: ${(props) => props.theme.spacing.inset};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    text-transform: capitalize;
    margin-bottom: 0;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s linear;

    &:hover {
        background-color: ${(props) => props.theme.colors.tertiary};
    }
`;

const GalleryArea = (props) => {
    const { title, galleryArea, brochure, buttonTitle = "Brochure" } = props;
    console.log("brochure", brochure.url)
    // rightGallery: Array(2)
    // leftGallery: Array(2)

    const linkItem = brochure ? brochure.url : "#";
    return (
        <Container>
            <Wrapper>
                <Title fontColor='primary' fontSize='medium'>
                    {title}
                </Title>
                {brochure ? <Button
                    href={linkItem}
                    bgcolor="primary"
                    fontcolor="defaultSecondary"
                    target={"_blank"}
                    rel="noopener noreferrer"
                >
                    {buttonTitle}
                </Button> : null}
            </Wrapper>

            <Gallery>
                {galleryArea?.length > 0 &&
                    galleryArea.map(
                        ({ ratio, rightGallery, leftGallery }, i) => {
                            // to grab the ratio as string
                            const ratioFirst = ratio.substring(0, 2);
                            const ratioSecond = ratio.substring(3, 5);

                            return (
                                <Row key={`gallery-area-row-${i}`}>
                                    <Column
                                        width={`${ratioFirst}%`}
                                        spacing='doubleInset'
                                    >
                                        {
                                            leftGallery?.length > 0 &&
                                            leftGallery.map((props, i) => (
                                                <GalleryContentBlocks
                                                    key={`gallery-area-left-column-${i}`}
                                                    tooSmall={
                                                        ratioFirst === '25'
                                                    }
                                                    {...props}
                                                />
                                            ))
                                        }
                                    </Column>
                                    <Column width={`${ratioSecond}%`}>
                                        {
                                            rightGallery?.length > 0 &&
                                            rightGallery.map((props, i) => (
                                                <GalleryContentBlocks
                                                    key={`gallery-area-right-column-${i}`}
                                                    tooSmall={
                                                        ratioSecond === '25'
                                                    }
                                                    {...props}
                                                />
                                            ))
                                        }
                                    </Column>
                                </Row>
                            );
                        }
                    )}
            </Gallery>
        </Container>
    );
};

export default GalleryArea;
