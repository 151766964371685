import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getAttachment } from '../../utils/data';

const Container = styled.div`
    flex: 0 0 72px;
    align-self:flex-start;
    margin-top:4px;
    background-image:url('${props => [props.bgImage]}');
    background-position:center center;
    background-size:cover;
    background-repeat:no-repeat;
    height:calc( 72px);
    border-radius:5px;

    @media(max-width:480px) {
        height:72px;
    }

	
`;

const Overlay =styled.div`
	background-color: #1d212699;
    z-index: 2;
    opacity: 0;
	font-weight: bold;
    color: #fff;
	width:100%;
	height:100%;
	justify-content: center;
	display: flex;
	align-items: center;
	cursor: pointer;
    border-radius:5px;
	transition: all 0.5s ease-in-out;
	text-align: center;

	&:hover{
		opacity: 1;
		border-radius:85%;

	}
`

const BGImage = ({ imgID = '', size = 'medium',color }) => {
    const [imgData, setThumbnailImage] = useState({});

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await getAttachment(imgID, size);
                setThumbnailImage(res);
            }
            catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [imgID, size]);
    
    return (
        <Container bgImage={imgData.url !== undefined ? imgData['wpmudev-cdn-url'] : 'https://placehold.it/800x600&text=No+Image'} >
			<Overlay>
			{color}

			</Overlay>
			</Container>
    );
}
export default BGImage;